<template>

<app-page :locked="is.moving">

	<app-page-head :back="back" noun="group" :title="title" :count="list.length">

		<app-page-head-search v-if="!isByLocation" v-model="searchText" :count="list.length" />
		<app-page-head-action v-if="!isByLocation" route="Group.Clone" icon="clone" tooltip="Clone group" />
		<app-page-head-action v-if="!isByLocation" route="Group" :params="{ group: 'new', parent: (category) ? category.id : false }" icon="create" tooltip="Add group" />
		<app-page-head-action v-on:click="onDeleteClick" :danger="true" :disabled="!selected.length" :count="selected.length" icon="delete" tooltip="Delete selected item(s)" />
		
	</app-page-head>

	<app-page-content :is-loading="is.moving" :is-empty="!list.length" empty-message="No groups found." :can-reorder="(isByCategory) ? order : false" v-on:reordered="onReordered">

		<com-item v-for="item in list" :key="item.id" :item="item" :can-order="isByCategory" :highlight="searchText" :selected="isSelected(item.id)" v-on:shiftclick="onSelectClick(item.id)" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixList from '@/mixin/list'

export default {

	mixins: [mixList],

	components: {
		'com-item': () => import('./groups/Item')
	},

	data: function() {

		return {
			store: 'groups'
		}

	},

	computed: {

		isByCategory: function() {

			return this.$route.meta.byCategory

		},

		isByLocation: function() {

			return this.$route.meta.byLocation

		},

		category: function() {

			return (this.isByCategory) ? this.$store.getters['categories/find'](this.$route.params.category) : false

		},

		location: function() {

			return (this.isByLocation) ? this.$store.getters['locations/find'](this.$route.params.location) : false

		},

		back: function() {

			if (this.category.id === 0) {

				return 'Categories'

			} else if (this.location.id === 0) {

				return 'Locations'

			} else if (this.isByCategory) {

				return 'Category'

			} else if (this.isByLocation) {

				return 'Location'

			} else {
			
				return false
			
			}

		},

		title: function() {

			if (this.isByCategory) {
			
				return this.category.title
			
			} else if (this.isByLocation) {
			
				return this.location.title
			
			} else {
			
				return 'Groups'

			}

		},

		categories: function() {

			return this.$store.getters['categories']

		},

		locations: function() {

			return this.$store.getters['locations']

		}

	},

	methods: {

		search: function(value) {

			return this.$util.search(value.title, this.searchText) 

		},

		filter: function(item) {

			if (this.isByCategory) {

				return item.category === this.category.id

			} else if (this.isByLocation) {

				var uses = 0

				this.$_.each(item.texts, function(id) {

					var text = this.$_.findWhere(this.$store.getters['texts'], {
						id: id
					})

					if (text) {

						if (text.location === this.location.id) uses++
					
					}
				
				}.bind(this))

				return uses > 0

			} else {

				return true

			}

		},

		sort: function(item) {

			if (this.isByCategory) {

				return (this.category.id) ? item.order : item.title

			} else if (this.isByLocation) {

				return (this.location.id) ? item.order : item.title

			} else {

				return this.$_.findWhere(this.categories, {
					id: item.category
				}).order + (item.order / 100)

			}

		}

	}

}

</script>

<style scoped>

</style>
